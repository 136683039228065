import React from "react";
import ReactDOM from "react-dom";
import "reflect-metadata";
import App from "./App";
import { ConfigProvider } from "antd";
import * as serviceWorker from "./serviceWorker";

declare global {
  interface Window {
    CSPSettings: any;
  }
}

window.CSPSettings = {
  nonce: "a+PeKqU9mvM2CNVsJk8OLw==",
};

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider csp={{ nonce: "a+PeKqU9mvM2CNVsJk8OLw==" }}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
