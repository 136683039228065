require("dotenv").config();

const NODE_ENV = process.env.NODE_ENV;
const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
const TRONSCAN_ADDRESS = process.env.REACT_APP_TRONSCAN_ADDRESS;
const EXPLORER_ADDRESS = process.env.REACT_APP_EXPLORER_ADDRESS;
const TRON_EXPLORER_ADDRESS = process.env.REACT_APP_TRON_EXPLORER_ADDRESS;
const RISE_CONTRACT_ADDRESS = process.env.REACT_APP_RISE_CONTRACT_ADDRESS;
const CASH_CONTRACT_ADDRESS = process.env.REACT_APP_CASH_CONTRACT_ADDRESS;
const TRON_MINTSAVER_ADDRESS = process.env.REACT_APP_TRON_MINTSAVER_ADDRESS;
const BSC_MINTSAVER_ADDRESS = process.env.REACT_APP_BSC_MINTSAVER_ADDRESS;
const TRON_PRIVATE_KEY = process.env.REACT_APP_TRON_PRIVATE_KEY;
const TRON_FULL_HOST = process.env.REACT_APP_TRON_FULL_HOST || "";
const REFRESH_TRANSACTION_DELAY =
  Number(process.env.REACT_APP_REFRESH_TRANSACTION_DELAY) || 30000;
const REFRESH_BALANCE_DELAY =
  Number(process.env.REACT_APP_REFRESH_BALANCE_DELAY) || 40000;
const REFRESH_TRON_BALANCE_DELAY =
  Number(process.env.REACT_APP_REFRESH_BALANCE_DELAY) || 1000000;
const KYC_REQUIRED = process.env.REACT_APP_KYC_REQUIRED;
const SEND_ENABLED = process.env.REACT_APP_SEND_ENABLED;
const CONVERT_ENABLED = process.env.REACT_APP_CONVERT_ENABLED;
const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
const TOKEN_DECIMALS = 100000000;
const BNB_DECIMALS = 10 ** 18;

const BSC_API_ADDRESS = process.env.REACT_APP_BSC_API_ADDRESS;
const BSC_API_KEY = process.env.REACT_APP_BSC_API_KEY;

const ESTIMATED_CONVERT_TOCNS = 0.0006;
const ESTIMATED_CONVERT_TOCNR = 0.0003;
const ESTIMATED_SEND = 0.0002;
const ESTIMATED_MAX_FEE = 0.0012;

const API_COINGEKO = "https://api.coingecko.com/api";
const TRONGRID_KEY_1 = process.env.REACT_APP_TRONGRID_KEY_1; // dev4
const TRONGRID_KEY_2 = process.env.REACT_APP_TRONGRID_KEY_2; // dev5
const TRONGRID_KEY_3 = process.env.REACT_APP_TRONGRID_KEY_3; // dev3
const TRONGRID_KEY_4 = process.env.REACT_APP_TRONGRID_KEY_4; // dev6
const TRONGRID_KEY_5 = process.env.REACT_APP_TRONGRID_KEY_5; // dev2
const TRONGRID_KEY_6 = process.env.REACT_APP_TRONGRID_KEY_6; // dev7
const TRONGRID_KEY_7 = process.env.REACT_APP_TRONGRID_KEY_7; // dev8
const TRONGRID_KEY_8 = process.env.REACT_APP_TRONGRID_KEY_8; // dev9
const TRONGRID_KEY_9 = process.env.REACT_APP_TRONGRID_KEY_9; // dev10
const TRONGRID_KEY_14 = process.env.REACT_APP_TRONGRID_KEY_14; // dev14
const TRONGRID_KEY_15 = process.env.REACT_APP_TRONGRID_KEY_15; // dev15
const TRONGRID_KEY_16 = process.env.REACT_APP_TRONGRID_KEY_16; // dev16
const TRONGRID_KEY_17 = process.env.REACT_APP_TRONGRID_KEY_17; // dev17
const TRONGRID_KEY_18 = process.env.REACT_APP_TRONGRID_KEY_18; // dev18
const TRONGRID_KEY_19 = process.env.REACT_APP_TRONGRID_KEY_19; // dev19
const TRONGRID_KEY_20 = process.env.REACT_APP_TRONGRID_KEY_20; // dev20

const SWAP_ADDRESS = process.env.REACT_APP_SWAP_ADDRESS || "";
const RISE_ADDRESS = process.env.REACT_APP_RISE_ADDRESS || "";

// remove for prod
const RISE_ADDRESS_PROD = process.env.REACT_APP_RISE_ADDRESS_PROD || "";

const BSC_RCP_ENDPOINTS = process.env.REACT_APP_BSC_RCP_ENDPOINTS || "";

// remove for prod
const BSC_RCP_ENDPOINTS_PROD =
  process.env.REACT_APP_BSC_RCP_ENDPOINTS_PROD || "";

const FEE_ADDRESS = process.env.REACT_APP_FEE_ADDRESS || "";

const RISE_ABI = [
  {
    inputs: [
      { internalType: "address", name: "_mintSaver", type: "address" },
      { internalType: "address", name: "_swapContract", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "admin",
        type: "address",
      },
    ],
    name: "AdminAppointed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "admin",
        type: "address",
      },
    ],
    name: "AdminDismissed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "blockNumber",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "risePrice",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "growthRate",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "change",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "created",
        type: "uint256",
      },
    ],
    name: "BlockCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "amountBurnt",
        type: "uint256",
      },
    ],
    name: "BurnSwap",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "converter",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "swapAmountSent",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "riseAmountReceived",
        type: "uint256",
      },
    ],
    name: "ConvertToRise",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "converter",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "riseAmountSent",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "swapAmountReceived",
        type: "uint256",
      },
    ],
    name: "ConvertToSwap",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "currentHour",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "riseAmountBurnt",
        type: "uint256",
      },
    ],
    name: "DoBalance",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "LostTokensBurnt",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "receiver",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "MintSwap",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "growthRate",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "priceFactor0",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "priceFactor1",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "priceFactor2",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "priceFactor3",
        type: "uint256",
      },
    ],
    name: "PriceFactorSet",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "QuarantineBalanceBurnt",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "spender", type: "address" },
    ],
    name: "allowance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_newAdmin", type: "address" }],
    name: "appointAdmin",
    outputs: [{ internalType: "bool", name: "success", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "burnLostTokens",
    outputs: [{ internalType: "bool", name: "_success", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "claimOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_swapAmount", type: "uint256" }],
    name: "convertToRise",
    outputs: [{ internalType: "bool", name: "_success", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_riseAmount", type: "uint256" }],
    name: "convertToSwap",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "subtractedValue", type: "uint256" },
    ],
    name: "decreaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_admin", type: "address" }],
    name: "dismissAdmin",
    outputs: [{ internalType: "bool", name: "success", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "doBalance",
    outputs: [{ internalType: "bool", name: "_success", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_blockNumber", type: "uint256" },
      { internalType: "uint256", name: "_growthRate", type: "uint256" },
    ],
    name: "doCreateBlock",
    outputs: [{ internalType: "bool", name: "_success", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_hoursEpoch", type: "uint256" }],
    name: "getBlockData",
    outputs: [
      { internalType: "uint256", name: "_risePrice", type: "uint256" },
      { internalType: "uint256", name: "_growthRate", type: "uint256" },
      { internalType: "uint256", name: "_change", type: "uint256" },
      { internalType: "uint256", name: "_created", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getCurrentHour",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getCurrentPrice",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getCurrentTime",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getOwner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_hour", type: "uint256" }],
    name: "getPrice",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    name: "growthRateToPriceFactors",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "hoursToBlock",
    outputs: [
      { internalType: "uint256", name: "risePrice", type: "uint256" },
      { internalType: "uint256", name: "growthRate", type: "uint256" },
      { internalType: "uint256", name: "change", type: "uint256" },
      { internalType: "uint256", name: "created", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "addedValue", type: "uint256" },
    ],
    name: "increaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "isAdmin",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isOwner",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lastBalancedHour",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lastBlockNumber",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lockPriceFactors",
    outputs: [{ internalType: "bool", name: "_success", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pendingOwner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "priceFactorsLocked",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "quarantineBalance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_growthRate", type: "uint256" },
      { internalType: "uint256[4]", name: "_priceFactors", type: "uint256[4]" },
    ],
    name: "setPriceFactors",
    outputs: [{ internalType: "bool", name: "_success", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "swapContract",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalBurnt",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
const SWAP_ABI = [
  {
    inputs: [{ internalType: "address", name: "_mintSaver", type: "address" }],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "admin",
        type: "address",
      },
    ],
    name: "AdminAppointed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "admin",
        type: "address",
      },
    ],
    name: "AdminDismissed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "spender", type: "address" },
    ],
    name: "allowance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_newAdmin", type: "address" }],
    name: "appointAdmin",
    outputs: [{ internalType: "bool", name: "success", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "tokensOwner", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
    ],
    name: "burnFromRise",
    outputs: [{ internalType: "bool", name: "_success", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "claimOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "subtractedValue", type: "uint256" },
    ],
    name: "decreaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_admin", type: "address" }],
    name: "dismissAdmin",
    outputs: [{ internalType: "bool", name: "success", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getOwner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "addedValue", type: "uint256" },
    ],
    name: "increaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "isAdmin",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isOwner",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
    ],
    name: "mintFromRise",
    outputs: [{ internalType: "bool", name: "_success", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pendingOwner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "riseContract",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_riseContractAddress",
        type: "address",
      },
    ],
    name: "setRiseContract",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalBurnt",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export {
  NODE_ENV,
  API_ADDRESS,
  TRONSCAN_ADDRESS,
  EXPLORER_ADDRESS,
  TRON_EXPLORER_ADDRESS,
  RISE_CONTRACT_ADDRESS,
  CASH_CONTRACT_ADDRESS,
  TRON_MINTSAVER_ADDRESS,
  BSC_MINTSAVER_ADDRESS,
  TRON_PRIVATE_KEY,
  TRON_FULL_HOST,
  REFRESH_TRANSACTION_DELAY,
  REFRESH_BALANCE_DELAY,
  REFRESH_TRON_BALANCE_DELAY,
  KYC_REQUIRED,
  SEND_ENABLED,
  CONVERT_ENABLED,
  RECAPTCHA_KEY,
  TOKEN_DECIMALS,
  BNB_DECIMALS,
  ESTIMATED_CONVERT_TOCNS,
  ESTIMATED_CONVERT_TOCNR,
  ESTIMATED_SEND,
  ESTIMATED_MAX_FEE,
  API_COINGEKO,
  SWAP_ADDRESS,
  RISE_ADDRESS,
  RISE_ABI,
  SWAP_ABI,
  BSC_RCP_ENDPOINTS,
  RISE_ADDRESS_PROD,
  BSC_RCP_ENDPOINTS_PROD,
  FEE_ADDRESS,
  BSC_API_ADDRESS,
  BSC_API_KEY,
  TRONGRID_KEY_1,
  TRONGRID_KEY_2,
  TRONGRID_KEY_3,
  TRONGRID_KEY_4,
  TRONGRID_KEY_5,
  TRONGRID_KEY_6,
  TRONGRID_KEY_7,
  TRONGRID_KEY_8,
  TRONGRID_KEY_9,
  TRONGRID_KEY_14,
  TRONGRID_KEY_15,
  TRONGRID_KEY_16,
  TRONGRID_KEY_17,
  TRONGRID_KEY_18,
  TRONGRID_KEY_19,
  TRONGRID_KEY_20,
};
