export const initPrice = () => ({
  type: "INIT_PRICE",
});

export const getPriceSuccess = (
  swapPrice: any,
  prev: any,
  current: any,
  next: any
) => ({
  type: "GET_PRICE_SUCCESS",
  swapPrice,
  prev,
  current,
  next,
});

export const getPriceFailure = (error: Error) => ({
  type: "GET_PRICE_FAILURE",
  error,
});
