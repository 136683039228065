import { TransactionActionService } from "./TransactionAction.store";

export const getTransactions = (payload: {
  token: string;
  userId: string;
  context: string;
}) => ({
  type: "GET_TRANSACTIONS",
  payload,
});

export const getTransactionsSuccess = (
  data: any[],
  page: number,
  pageSize: number,
  results: number
) => ({
  type: "GET_TRANSACTIONS_SUCCESS",
  data,
  page,
  pageSize,
  results,
});

export const getTransactionsFailure = (error: Error) => ({
  type: "GET_TRANSACTIONS_FAILURE",
  error,
});

export const stopTransactionsSync = () => ({
  type: "STOP_TRANSACTIONS_SYNC",
});

export const stopTransactionsSpeedSync = () => ({
  type: "STOP_TRANSACTIONS_SPEED_SYNC",
});

export const clearTransactions = () => ({
  type: "CLEAR_TRANSACTIONS",
});

export const doTransaction = (
  formData: {
    toAddress: string;
    amount: string;
    currency: string;
    code: string;
  },
  payload: { token: string; userId: string },
  transactionType: string,
  paymentInfo: {
    cnrFee: string;
    cnsFee: string;
  }
) => {
  const transactionActionService = new TransactionActionService();
  return transactionActionService.createTransaction(
    formData,
    payload,
    transactionType,
    paymentInfo
  );
};
