import {
  call,
  all,
  put,
  takeLatest,
  take,
  fork,
  cancel,
  delay,
} from "redux-saga/effects";
import { getTronSuccess, getTronFailure } from "./Tron.actions";
import {
  initPrice,
  getPriceSuccess,
  getPriceFailure,
} from "../Price/Price.actions";
import {
  getBalanceSuccess,
  getBalanceFailure,
  getTrc20BalanceSuccess,
} from "../Balance/Balance.actions";
import {
  getMetrics,
  getMetricsSuccess,
  getMetricsFailure,
} from "../Metrics/Metrics.actions";
import {
  REFRESH_BALANCE_DELAY,
  REFRESH_TRON_BALANCE_DELAY,
} from "../../config";

import BscService from "./Bsc.service";
import TronService from "./Tron.service";

function* initTronSaga() {
  try {
    yield all([
      call(TronService.initContracts),
      call(BscService.initContracts),
    ]);
    yield all([put(getTronSuccess()), put(initPrice()), put(getMetrics())]);

    const { swapPrice, current, next, prev } = yield call(
      BscService.getPriceData
    );

    yield put(getPriceSuccess(swapPrice, prev, current, next));
  } catch (error) {
    yield all([put(getTronFailure(error)), put(getPriceFailure(error))]);
  }
}

function* getPriceSaga() {
  try {
    const { swapPrice, current, next, prev } = yield call(
      BscService.getPriceData
    );
    yield put(getPriceSuccess(swapPrice, prev, current, next));
  } catch (error) {
    yield all([put(getPriceFailure(error))]);
  } finally {
  }
}

function* getMetricsSaga() {
  try {
    const { latestBurn, circulatingRise } = yield call(
      BscService.getKeyMetrics
    );
    yield put(getMetricsSuccess(latestBurn, circulatingRise));
  } catch (error) {
    yield all([put(getMetricsFailure(error))]);
  } finally {
  }
}

function* getBalanceSaga(action) {
  try {
    const { wallet } = action;
    const { rise, swap, bnb } = yield call(BscService.getBalanceData, wallet);
    yield put(getBalanceSuccess(rise, swap, bnb));
  } catch (error) {
    yield all([put(getBalanceFailure(error))]);
  } finally {
  }
}

function* balanceSync(wallet) {
  try {
    while (true) {
      const { rise, swap, bnb } = yield call(BscService.getBalanceData, wallet);
      yield put(getBalanceSuccess(rise, swap, bnb));
      yield delay(Number(REFRESH_BALANCE_DELAY));
    }
  } catch (error) {
    yield all([put(getBalanceFailure(error))]);
  } finally {
  }
}

function* balanceSyncSaga(action) {
  try {
    const { wallet } = action;
    while (yield take("START_BALANCE_SYNC")) {
      const balanceSyncTask = yield fork(balanceSync, wallet);
      yield take("STOP_BALANCE_SYNC");
      yield cancel(balanceSyncTask);
    }
  } catch (error) {
    yield all([put(getBalanceFailure(error))]);
  } finally {
  }
}

function* trc20BalanceSync(tronWallet) {
  try {
    while (true) {
      const { rise: trc20Rise, cash } = yield call(
        TronService.getBalanceData,
        tronWallet
      );

      yield put(getTrc20BalanceSuccess(trc20Rise, cash));
      yield delay(Number(REFRESH_TRON_BALANCE_DELAY));
    }
  } catch (error) {
    yield all([put(getBalanceFailure(error))]);
  } finally {
  }
}

function* trc20BalanceSyncSaga(action) {
  try {
    const { tronWallet } = action;

    while (yield take("START_BALANCE_SYNC")) {
      const trc20BalanceSyncTask = yield fork(trc20BalanceSync, tronWallet);
      yield take("STOP_BALANCE_SYNC");
      yield cancel(trc20BalanceSyncTask);
    }
  } catch (error) {
    console.info(error);
  } finally {
  }
}

export function* tronSagas() {
  yield takeLatest("INIT_TRON", initTronSaga);
  yield takeLatest("INIT_BALANCE_SYNC", balanceSyncSaga);
  yield takeLatest("INIT_BALANCE_SYNC", trc20BalanceSyncSaga);
  yield takeLatest("GET_PRICE", getPriceSaga);
  yield takeLatest("GET_METRICS", getMetricsSaga);
  yield takeLatest("GET_BALANCE", getBalanceSaga);
}
