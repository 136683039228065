import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch } from "react-router-dom";
import RouteWrapper from "./RouteWrapper";
import { AUTH_STATUS } from "../../containers/Auth/Auth.constants";
import { LoadingFrame } from "../../components/Shared";
import { Layout, Grid } from "antd";
import "./private.scss";

const { useBreakpoint } = Grid;

const DashboardHeader = lazy(() =>
  import("../../components/DashboardHeader/DashboardHeader")
);
const DashboardFooter = lazy(() =>
  import("../../components/DashboardFooter/DashboardFooter")
);
const DashboardMenu = lazy(() =>
  import("../../components/DashboardMenu/DashboardMenu")
);
const Dashboard = lazy(() => import("./Dashboard/Dashboard"));
const AuthModal = lazy(() => import("../../components/AuthModal/AuthModal"));
const Transactions = lazy(() => import("./Transactions/Transactions"));
const Buy = lazy(() => import("./Buy/Buy"));
const Sell = lazy(() => import("./Sell/Sell"));
const Account = lazy(() => import("./Account/Account"));
const Security = lazy(() => import("./Security/Security"));
const NotFound = lazy(() => import("./NotFound/NotFound"));

const PrivateRouteWrapper = () => {
  const dispatch = useDispatch();
  const { status } = useSelector((state: any) => state.Auth);
  const screens = useBreakpoint();
  const [responsiveClasses, setResponsiveClasses] = useState("");
  const [isMobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    let responsiveString = "";
    let isMobile = true;
    Object.entries(screens)
      .filter((screen) => !!screen[1])
      .forEach((screen) => {
        if (screen[0] === "md") {
          isMobile = false;
        }
        responsiveString += ` screen-${screen[0]}`;
      });
    setResponsiveClasses(responsiveString);
    setMobileMenu(isMobile);
  }, [screens]);

  useEffect(() => {
    if (status === AUTH_STATUS.IDLE) {
      dispatch({
        type: "GET_AUTH",
      });
    }
  }, [dispatch, status]);

  useEffect(() => {
    dispatch({
      type: "INIT_TRON",
    });
  }, [dispatch]);

  return (
    <Layout className={responsiveClasses}>
      <DashboardMenu isMobileMenu={isMobileMenu} />
      <Layout className="content-container">
        <DashboardHeader />
        <Suspense fallback={<LoadingFrame />}>
          <Switch>
            <RouteWrapper path="/" exact component={Dashboard} />
            <RouteWrapper
              path="/transactions/:token"
              exact
              component={Transactions}
            />
            <RouteWrapper path="/security" exact component={Security} />
            <RouteWrapper path="/account/:page" exact component={Account} />
            <RouteWrapper path="/buy" exact component={Buy} />
            <RouteWrapper path="/sell" exact component={Sell} />
            <RouteWrapper path="*" component={NotFound} />
          </Switch>
        </Suspense>
        <DashboardFooter />
        {(status === AUTH_STATUS.FAILURE || status === AUTH_STATUS.IDLE) && (
          <AuthModal />
        )}
      </Layout>
    </Layout>
  );
};

export default PrivateRouteWrapper;
