import axios from "axios";
import { API_ADDRESS } from "../../config";
import { LOGIN_STATUS } from "./Auth.constants";

export class AuthService {
  private getLocalAuth = (tokenType) => {
    if (tokenType === "local") {
      return {
        token: localStorage.getItem("CENTRIC-token") || null,
        userId: localStorage.getItem("CENTRIC-id") || null,
      };
    } else if (tokenType === "session") {
      return {
        token: sessionStorage.getItem("CENTRIC-token") || null,
        userId: sessionStorage.getItem("CENTRIC-id") || null,
      };
    } else {
      return {
        token: null,
        userId: null,
      };
    }
  };

  private setLocalAuth = (remember, token, user) => {
    if (remember) {
      localStorage.setItem("CENTRIC-type", "local");
      localStorage.setItem("CENTRIC-token", token);
      localStorage.setItem("CENTRIC-id", user.id);
    } else {
      localStorage.setItem("CENTRIC-type", "session");
      sessionStorage.setItem("CENTRIC-token", token);
      sessionStorage.setItem("CENTRIC-id", user.id);
    }
  };

  public getAuth = async () => {
    try {
      const tokenType = localStorage.getItem("CENTRIC-type");
      if (!tokenType) throw new Error("User not authenticated");

      const { token, userId } = this.getLocalAuth(tokenType);

      if (!token || !userId) throw new Error("User not authenticated");

      return {
        token,
        userId,
      };
    } catch (e) {
      throw e;
    }
  };

  public clearAuth = async () => {
    try {
      localStorage.clear();
      sessionStorage.clear();
      return;
    } catch (e) {
      console.info(e);
      throw e;
    }
  };

  private doSendVerifyEmail = (email, password, code) => {
    axios.post(`${API_ADDRESS}/users/verify_email`, {
      email,
      password,
      code,
    });
  };

  private parseError = (status, data, email, password, code) => {
    /*
      {statusCode: 401, error: "Unauthorized", message: "2FA code not provided"}
      {statusCode: 401, error: "Unauthorized", message: "Invalid email or password"}
      {statusCode: 401, error: "Unauthorized", message: "2FA code is incorrect or expired"}
      {statusCode:401, error: "Unauthorized","message":"Please verify your email"}
      {statusCode: 401, error: "Unauthorized","message": "Signup currently disabled"}
      400: [{"property":"code","constraints":{"maxLength":"code must be shorter than or equal to 6 characters","isString":"code must be a string"}}]
      422: {"statusCode":422,"error":"Unprocessable Entity","message":"User email already exists"}
      429: {"statusCode":429,"error":"Too Many Requests","message":"114.73.76.205: too many requests"}
      401: {"statusCode":401,"error":"Unauthorized","message":"Your account has been blocked for security reasons"}
      401: {"statusCode":401,"error":"Unauthorized","message":"Your account has been blocked for breaching airdrop terms - 1 account per person."}
    */

    switch (status) {
      case 400: {
        const errors: any = [];
        data.forEach((err) => {
          errors.push({
            name: err.property,
            error: [Object.values(err.constraints)],
          });
        });
        return {
          status: errors.find((item) => item.name === "code")
            ? LOGIN_STATUS.TWOFA_ERROR
            : LOGIN_STATUS.ERROR,
          message: "error",
          errorFields: errors.map((err) => {
            return {
              name: [err.name],
              errors: [err.error],
            };
          }),
        };
      }
      case 401: {
        const { error, message } = data;
        if (message.toLowerCase() === "invalid email or password") {
          return {
            status: LOGIN_STATUS.ERROR,
            message: error,
            errorFields: [
              {
                name: ["email"],
                errors: [message],
              },
              {
                name: ["password"],
                errors: [message],
              },
            ],
          };
        } else if (message.toLowerCase() === "2fa code not provided") {
          return {
            status: LOGIN_STATUS.TWOFA,
            message: error,
            errorFields: [],
          };
        } else if (
          message.toLowerCase() === "2fa code is incorrect or expired"
        ) {
          return {
            status: LOGIN_STATUS.TWOFA_ERROR,
            message: error,
            errorFields: [
              {
                name: ["code"],
                errors: [message],
              },
            ],
          };
        } else if (
          message.toLowerCase() ===
            `please verify your email ${email.toLowerCase()}` ||
          message.toLowerCase() === "please verify your email"
        ) {
          this.doSendVerifyEmail(email, password, code);
          return {
            status: LOGIN_STATUS.VERIFY_EMAIL,
            message: error,
            errorFields: [],
          };
        } else if (
          message.toLowerCase() === `signup currently disabled` ||
          message.toLowerCase() === `login currently disabled`
        ) {
          return {
            status: LOGIN_STATUS.UNKNOWN_ERROR,
            message: message,
            errorFields: [],
          };
        } else if (
          message.toLowerCase() ===
          `your account has been blocked for security reasons`
        ) {
          return {
            status: LOGIN_STATUS.UNKNOWN_ERROR,
            message: message,
            errorFields: [],
          };
        } else if (
          message.toLowerCase() ===
          `your account has been blocked for breaching airdrop terms - 1 account per person`
        ) {
          return {
            status: LOGIN_STATUS.UNKNOWN_ERROR,
            message: message,
            errorFields: [],
          };
        } else {
          return {
            status: LOGIN_STATUS.UNKNOWN_ERROR,
            message: error,
            errorFields: [],
          };
        }
      }
      case 422: {
        const { error, message } = data;
        if (
          message.toLowerCase() === `user ${email.toLowerCase()} already exists`
        ) {
          return {
            status: LOGIN_STATUS.ERROR,
            message: error,
            errorFields: [
              {
                name: ["email"],
                errors: [message],
              },
            ],
          };
        } else {
          return {
            status: LOGIN_STATUS.UNKNOWN_ERROR,
            message: error,
            errorFields: [],
          };
        }
      }
      case 429: {
        const { error } = data;
        return {
          status: LOGIN_STATUS.UNKNOWN_ERROR,
          message: error,
          errorFields: [],
        };
      }
    }

    return {
      status: LOGIN_STATUS.UNKNOWN_ERROR,
      message: "Unknown Error",
      errorFields: [],
    };
  };

  public doLogin = async (loginData) => {
    try {
      const { email, password, code, remember, captcha } = loginData;
      const { data, status } = await axios.post(
        `${API_ADDRESS}/auth/login`,
        {
          email,
          password,
          code,
          captcha,
        },
        {
          validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (status >= 400) {
        return this.parseError(status, data, email, password, code);
      }

      const { token, user } = data;

      this.setLocalAuth(remember, token, user);

      return {
        errorFields: [],
        message: "success",
        status: LOGIN_STATUS.SUCCESS,
        token: token,
        user: user,
      };
    } catch (e) {
      throw e;
    }
  };

  public doResetTwoFa = async (loginData) => {
    try {
      const { email, password } = loginData;

      const { data, status } = await axios.post(
        `${API_ADDRESS}/auth/lost-2fa`,
        {
          email,
          password,
        },
        {
          validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (status >= 400) {
        return this.parseError(status, data, email, password, null);
      }

      return {
        errorFields: [],
        message: "2fa reset",
        status: LOGIN_STATUS.VERIFY_EMAIL,
      };
    } catch (e) {
      throw e;
    }
  };

  public doPasswordReset = async (formData) => {
    try {
      const { email, captcha } = formData;
      const { status, data } = await axios.post(
        `${API_ADDRESS}/auth/forgot-password`,
        { email, captcha },
        {
          validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (status >= 400) {
        return this.parseError(status, data, email, null, null);
      }

      return {
        errorFields: [],
        message: "Password reset",
        status: LOGIN_STATUS.VERIFY_EMAIL,
      };
    } catch (e) {
      throw e;
    }
  };

  public confirmResetTwoFa = async (token) => {
    try {
      const { status } = await axios.put(
        `${API_ADDRESS}/auth/reset-2fa`,
        { token },
        {
          validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (status >= 400) {
        if (status === 401) {
          return {
            validateStatus: "error",
            errorFields: [],
          };
        }
        return {
          validateStatus: "failure",
          errorFields: [],
        };
      }

      return {
        validateStatus: "success",
        errorFields: [],
      };
    } catch (e) {
      throw e;
    }
  };

  public verifyEmail = async (token) => {
    try {
      const { status } = await axios.get(
        `${API_ADDRESS}/users/verify_email/${token}`,
        {
          validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (status >= 400) {
        if (status === 401) {
          return {
            validateStatus: "error",
            errorFields: [],
          };
        }
        return {
          validateStatus: "failure",
          errorFields: [],
        };
      }

      return {
        validateStatus: "success",
        errorFields: [],
      };
    } catch (e) {
      throw e;
    }
  };

  public confirmSecurity = async (token) => {
    try {
      const { status } = await axios.put(
        `${API_ADDRESS}/auth/activate-2fa/`,
        { token },
        {
          validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (status >= 400) {
        if (status === 401) {
          return {
            validateStatus: "error",
            errorFields: [],
          };
        }
        return {
          validateStatus: "failure",
          errorFields: [],
        };
      }

      return {
        validateStatus: "success",
        errorFields: [],
      };
    } catch (e) {
      throw e;
    }
  };

  public createPassword = async (formData, payload) => {
    try {
      const { newPassword: password } = formData;
      const { token } = payload;

      const { data, status } = await axios.put(
        `${API_ADDRESS}/auth/reset-password`,
        { password, token },
        {
          validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (status >= 400) {
        if (status === 401) {
          const { message } = data;
          if (message.toLowerCase() === "incorrect token") {
            return {
              validateStatus: "error",
              errorFields: [
                {
                  name: ["newPassword"],
                  errors: ["Token expired please start again"],
                },
              ],
            };
          }
        }
        const errors: any[] = [];
        data.forEach((err) => {
          errors.push({
            name: ["newPassword"],
            error: [Object.values(err.constraints)],
          });
        });
        return {
          validateStatus: "error",
          errorFields: errors.map((err) => {
            return {
              name: ["newPassword"],
              value: "",
              errors: [err.error],
            };
          }),
        };
      }

      return {
        validateStatus: "success",
        errorFields: [],
      };
    } catch (e) {
      throw e;
    }
  };

  public doSignup = async (signupData) => {
    try {
      const { email, password, captcha } = signupData;

      const { data, status } = await axios.post(
        `${API_ADDRESS}/users`,
        {
          email,
          password,
          captcha,
          blockchain: "BSC",
        },
        {
          validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (status >= 400) {
        return this.parseError(status, data, email, null, null);
      }

      const { token, user, requireEmailVerification } = data;

      if (requireEmailVerification) {
        return {
          status: LOGIN_STATUS.VERIFY_EMAIL,
          message: "Email verification required",
          errorFields: [],
        };
      }

      this.setLocalAuth(false, token, user);

      return {
        errorFields: [],
        message: "success",
        status: LOGIN_STATUS.SUCCESS,
        token: token,
        user: user,
      };
    } catch (e) {
      throw e;
    }
  };

  public updatePassword = async (formData, payload) => {
    try {
      const { currentPassword, newPassword } = formData;
      const { token, userId } = payload;

      const { data, status } = await axios.put(
        `${API_ADDRESS}/auth/${userId}/update-password`,
        { currentPassword, newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (status >= 400) {
        const errors: any[] = [];
        data.forEach((err) => {
          errors.push({
            name: err.property,
            error: [Object.values(err.constraints)],
          });
        });
        return {
          validateStatus: "error",
          errorFields: errors.map((err) => {
            return {
              name: [err.name],
              value: "",
              errors: [err.error],
            };
          }),
        };
      }

      return {
        validateStatus: "success",
        errorFields: [],
      };
    } catch (e) {
      throw e;
    }
  };
}
