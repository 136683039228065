import { TRON_STATES } from "./Tron.constants";

const initialState = {
  status: TRON_STATES.idle,
  error: null,
};

const Tron = (
  state = initialState,
  action: {
    type: string;
    error: any;
  }
) => {
  switch (action.type) {
    case "INIT_TRON":
    case "GET_TRON":
      return {
        status: TRON_STATES.loading,
        error: null,
      };
    case "GET_TRON_SUCCESS":
      return {
        ...state,
        status: TRON_STATES.success,
        error: null,
      };
    case "GET_PRICE_FAILURE":
      return {
        ...initialState,
        status: TRON_STATES.failure,
        error: action.error,
      };
    default:
      return state;
  }
};

export default Tron;
