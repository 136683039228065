import { SecurityService } from "./Security.store";

export const getSecurity = (payload: { token: string; userId: string }) => ({
  type: "GET_SECURITY",
  payload,
});

export const getSecuritySuccess = (data: any) => ({
  type: "GET_SECURITY_SUCCESS",
  data,
});

export const getSecurityFailure = (error: Error) => ({
  type: "GET_SECURITY_FAILURE",
  error,
});

export const clearSecurity = () => ({
  type: "CLEAR_SECURITY",
});

export const updateSecurity = (payload: {
  code: number;
  isActive: boolean;
  token: string;
  userId: string;
  password: string;
}) => {
  const securityService = new SecurityService();
  return securityService.updateSecurity(payload);
};
