import axios from "axios";
import { API_ADDRESS } from "../../config";

export class KycService {
  public getKyc = async (payload) => {
    try {
      const { token, userId } = payload;
      const { data } = await axios.get(`${API_ADDRESS}/users/${userId}/kyc`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.files) {
        const { selfie, document } = data.files;
        return {
          ...data,
          selfie,
          document,
        };
      }
      return data;
    } catch (e) {
      console.info(e);
      throw e;
    }
  };

  public updateKyc = async (payload) => {
    try {
      const { token, userId, kycData } = payload;
      const { data, status } = await axios.put(
        `${API_ADDRESS}/users/${userId}/kyc`,
        {
          ...kycData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (status >= 400) {
        if (data.length) {
          for (var i = 0; i < data.length; i++) {
            const { constraints, property } = data[i];
            if (typeof constraints.PersonName !== "undefined") {
              switch (property) {
                case "firstName":
                  throw new Error(
                    "First Name Error: Please use only standard English charaters"
                  );
                case "lastName":
                  throw new Error(
                    "Last Name Error: Please use only standard English charaters"
                  );
                default:
                  throw new Error(constraints.PersonName);
              }
            }
          }
        } else {
          throw new Error("Updating kyc failed");
        }
      }

      if (data.files) {
        const { selfie, document } = data.files;
        return {
          ...data,
          selfie,
          document,
        };
      }
      return data;
    } catch (e) {
      console.info(e);
      throw e;
    }
  };
}
