import { AUTH_STATUS } from "./Auth.constants";

const initialState = {
  status: AUTH_STATUS.IDLE,
  userId: null,
  token: null,
  error: null,
};

const Auth = (
  state = initialState,
  action: {
    type: string;
    token: string;
    userId: number;
    error: any;
  }
) => {
  switch (action.type) {
    case "GET_AUTH":
      return {
        ...state,
        status: AUTH_STATUS.LOADING,
      };
    case "GET_AUTH_SUCCESS":
      return {
        ...state,
        status: AUTH_STATUS.SUCCESS,
        token: action.token,
        userId: action.userId,
        error: null,
      };
    case "GET_AUTH_FAILURE":
      return {
        ...initialState,
        status: AUTH_STATUS.FAILURE,
        error: action.error,
      };
    case "CLEAR_AUTH_SUCCESS":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default Auth;
