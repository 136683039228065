import {
  call,
  put,
  all,
  cancel,
  fork,
  delay,
  take,
  takeLatest,
} from "redux-saga/effects";
import { REFRESH_TRANSACTION_DELAY } from "../../config";

import {
  getTransactionsSuccess,
  getTransactionsFailure,
} from "./Transactions.actions";
import { TransactionsService } from "./Transactions.store";

function* transactionsSync(payload) {
  try {
    const transactionsService = new TransactionsService();
    while (true) {
      const { data, page, pageSize, results } = yield call(
        transactionsService.getTransactions,
        payload
      );
      yield put(getTransactionsSuccess(data, page, pageSize, results));
      yield delay(Number(REFRESH_TRANSACTION_DELAY));
    }
  } catch (error) {
    yield all([put(getTransactionsFailure(error))]);
  } finally {
  }
}

function* transactionsSaga(action) {
  const { payload } = action;
  while (yield take("START_TRANSACTIONS_SYNC")) {
    const transactionsSyncTask = yield fork(transactionsSync, payload);
    yield take("STOP_TRANSACTIONS_SYNC");
    yield cancel(transactionsSyncTask);
  }
}

function* transactionsSpeedSync(payload) {
  try {
    const transactionsService = new TransactionsService();
    while (true) {
      const { data, page, pageSize, results } = yield call(
        transactionsService.getTransactionsWithFailed,
        payload
      );
      yield put(getTransactionsSuccess(data, page, pageSize, results));
      yield delay(5000);
    }
  } catch (error) {
    yield all([put(getTransactionsFailure(error))]);
  } finally {
  }
}

function* transactionsSpeedSaga(action) {
  const { payload } = action;
  while (yield take("START_TRANSACTIONS_SPEED_SYNC")) {
    const transactionsSpeedSyncTask = yield fork(
      transactionsSpeedSync,
      payload
    );
    yield take("STOP_TRANSACTIONS_SPEED_SYNC");
    yield cancel(transactionsSpeedSyncTask);
  }
}

export function* transactionsSagas() {
  yield takeLatest("GET_TRANSACTIONS", transactionsSaga);
  yield takeLatest("GET_TRANSACTIONS_SPEED", transactionsSpeedSaga);
}
