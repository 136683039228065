import * as TronWeb from "tronweb";
import axios from "axios";
import { getMinutes } from "date-fns";
import {
  NODE_ENV,
  RISE_CONTRACT_ADDRESS,
  CASH_CONTRACT_ADDRESS,
  TRON_FULL_HOST,
  TRON_PRIVATE_KEY,
  TOKEN_DECIMALS,
  API_ADDRESS,
  TRONGRID_KEY_1,
  TRONGRID_KEY_2,
  TRONGRID_KEY_3,
  TRONGRID_KEY_4,
  TRONGRID_KEY_5,
  TRONGRID_KEY_6,
  TRONGRID_KEY_7,
  TRONGRID_KEY_8,
  TRONGRID_KEY_9,
  TRONGRID_KEY_14,
  TRONGRID_KEY_15,
  TRONGRID_KEY_16,
  TRONGRID_KEY_17,
  TRONGRID_KEY_18,
  TRONGRID_KEY_19,
} from "../../config";
import { divide, formatDecimalFromCentric } from "../../utils/formatNumber";

class TronService {
  public riseContract: any;
  public cashContract: any;
  public tronWeb: any;
  public tronWeb2: any;

  private getTronGridKey(currentMinutesForRotate) {
    if (currentMinutesForRotate < 4) {
      return TRONGRID_KEY_1;
    }
    if (currentMinutesForRotate < 8) {
      return TRONGRID_KEY_2;
    }
    if (currentMinutesForRotate < 12) {
      return TRONGRID_KEY_3;
    }
    if (currentMinutesForRotate < 16) {
      return TRONGRID_KEY_4;
    }
    if (currentMinutesForRotate < 20) {
      return TRONGRID_KEY_5;
    }
    if (currentMinutesForRotate < 24) {
      return TRONGRID_KEY_6;
    }
    if (currentMinutesForRotate < 28) {
      return TRONGRID_KEY_7;
    }
    if (currentMinutesForRotate < 32) {
      return TRONGRID_KEY_8;
    }
    if (currentMinutesForRotate < 36) {
      return TRONGRID_KEY_9;
    }
    if (currentMinutesForRotate < 40) {
      return TRONGRID_KEY_14;
    }
    if (currentMinutesForRotate < 44) {
      return TRONGRID_KEY_15;
    }
    if (currentMinutesForRotate < 48) {
      return TRONGRID_KEY_16;
    }
    if (currentMinutesForRotate < 52) {
      return TRONGRID_KEY_17;
    }
    if (currentMinutesForRotate < 56) {
      return TRONGRID_KEY_18;
    }
    return TRONGRID_KEY_19;
  }

  constructor(fullHost: string) {
    const fullHosts = fullHost.split(",");
    var currentMinutesForRotate = getMinutes(new Date());
    const currentFullHost = new TronWeb.providers.HttpProvider(
      fullHosts[0],
      2 * 60 * 1000
    );

    this.tronWeb = new TronWeb({
      fullHost: currentFullHost,
      headers:
        NODE_ENV === "development" || !currentFullHost.host.includes("trongrid")
          ? {}
          : {
              "TRON-PRO-API-KEY": this.getTronGridKey(currentMinutesForRotate),
            },
      privateKey: TRON_PRIVATE_KEY,
    });

    const fullHost2 = new TronWeb.providers.HttpProvider(
      fullHosts[1],
      2 * 60 * 1000
    );
    this.tronWeb2 = new TronWeb({
      fullHost: fullHost2,
      headers:
        NODE_ENV === "development" || !fullHost2.host.includes("trongrid")
          ? {}
          : {
              "TRON-PRO-API-KEY": this.getTronGridKey(currentMinutesForRotate),
            },
      privateKey: TRON_PRIVATE_KEY,
    });
  }

  public toDecimal = (...args: any[]) => this.tronWeb.toDecimal(...args);
  public fromSun = (...args: any[]) => this.tronWeb.fromSun(...args);

  private hostIndex = 1;
  private getNextFullHost() {
    const fullHosts = TRON_FULL_HOST.split(",");
    const host = fullHosts[this.hostIndex];
    this.hostIndex =
      this.hostIndex === fullHosts.length - 1 ? 0 : ++this.hostIndex;
    return host;
  }

  protected async updateContractInstance() {
    var currentMinutesForRotate = getMinutes(new Date());
    const currentFullHost = new TronWeb.providers.HttpProvider(
      this.getNextFullHost(),
      2 * 60 * 1000
    );

    this.tronWeb = new TronWeb({
      fullHost: currentFullHost,
      headers:
        NODE_ENV === "development" || !currentFullHost.host.includes("trongrid")
          ? {}
          : {
              "TRON-PRO-API-KEY": this.getTronGridKey(currentMinutesForRotate),
            },
      privateKey: TRON_PRIVATE_KEY,
    });
  }

  public initContracts = async (counter = 0) => {
    try {
      if (!this.tronWeb2) throw new Error();

      const risePromise = this.tronWeb2.contract().at(RISE_CONTRACT_ADDRESS);
      const cashPromise = this.tronWeb2.contract().at(CASH_CONTRACT_ADDRESS);
      const [rise, cash] = await Promise.all([risePromise, cashPromise]);

      this.riseContract = rise;
      this.cashContract = cash;

      return {
        rise,
        cash,
      };
    } catch (e) {
      if (counter < 5) {
        await this.updateContractInstance();
        return this.initContracts(++counter);
      }
      throw new Error("Error connecting to tron service");
    }
  };

  public getPriceData = async () => {
    try {
      const blockNumberHex = await this.riseContract.getCurrentHour().call();
      const blockNumber = await this.toDecimal(blockNumberHex);
      const cashPricePromise: any = axios.get(
        `${API_ADDRESS}/public/contract/cmc-cns-quote`
      );
      const currentPricePromise = this.riseContract
        .getBlockData(blockNumber)
        .call();
      const nextPricePromise = this.riseContract
        .getBlockData(blockNumber + 1)
        .call();
      const [
        { data: cashPriceResult },
        { _risePrice: currentRisePriceHex },
        { _risePrice: nextRisePriceHex },
      ] = await Promise.all([
        cashPricePromise,
        currentPricePromise,
        nextPricePromise,
      ]);

      const cashPrice = cashPriceResult.data["5963"].quote.USD.price;

      const currentPrice = divide(
        await this.toDecimal(currentRisePriceHex),
        TOKEN_DECIMALS
      );

      const nextPrice = divide(
        await this.toDecimal(nextRisePriceHex),
        TOKEN_DECIMALS
      );

      return {
        cashPrice: cashPrice,
        current: {
          currentPrice,
          blockNumber,
        },
        next: {
          nextPrice,
          blockNumber: blockNumber + 1,
        },
      };
    } catch (e) {
      console.info("Error getting price data");
      throw new Error("Error getting price data");
    }
  };

  public getBalanceInSun = (address?: string): Promise<number> => {
    return this.tronWeb.trx.getUnconfirmedBalance(address);
  };

  public getBalanceData = async (tronWallet) => {
    try {
      if (!tronWallet) {
        return {
          rise: "0",
          cash: "0",
        };
      }

      if (!this.riseContract || !this.cashContract) {
        await this.initContracts();
      }

      const riseBalancePromise = this.riseContract.balanceOf(tronWallet).call();
      const cashBalancePromise = this.cashContract.balanceOf(tronWallet).call();

      const [riseBalance, cashBalance] = await Promise.all([
        riseBalancePromise,
        cashBalancePromise,
      ]);
      const rise = formatDecimalFromCentric(riseBalance);
      const cash = formatDecimalFromCentric(cashBalance);
      return {
        rise: rise,
        cash: cash,
      };
    } catch (e) {
      console.info(e);
      throw new Error("Error getting balance data");
    }
  };
}

const tronService = new TronService(TRON_FULL_HOST);

export default tronService;
