import { USER_STATES } from "./User.constants";

const initialState = {
  status: USER_STATES.idle,
  data: {},
  error: null,
};

const User = (
  state = initialState,
  action: {
    type: string;
    data: any;
    error: any;
  }
) => {
  switch (action.type) {
    case "INIT_USER":
    case "GET_USER":
      return {
        ...state,
        status: USER_STATES.loading,
        error: null,
      };
    case "GET_USER_SUCCESS":
      return {
        ...state,
        status: USER_STATES.success,
        data: action.data,
        error: null,
      };
    case "GET_USER_FAILURE":
      return {
        ...initialState,
        status: USER_STATES.failure,
        error: action.error,
      };
    case "CLEAR_USER":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default User;
