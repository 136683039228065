import axios from "axios";
import { API_ADDRESS } from "../../config";
import { format } from "date-fns";

export class UserService {
  public getUser = async (payload) => {
    try {
      const { token, userId } = payload;
      const { data } = await axios.get(`${API_ADDRESS}/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (e) {
      throw e;
    }
  };

  public updateUser = async (userData, payload) => {
    try {
      const { firstName, lastName, country } = userData;
      const { token, userId } = payload;
      const { data } = await axios.put(
        `${API_ADDRESS}/users/${userId}`,
        { firstName, lastName, country },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (e) {
      throw e;
    }
  };

  public getActivity = async (payload) => {
    try {
      const { token, userId } = payload;
      const { data } = await axios.get(
        `${API_ADDRESS}/users/${userId}/events`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const userActivity = data
        .map((item) => {
          return {
            ...item,
            date: format(item.dateCreated, "MM-dd-yyyy kk:mm:ss"),
          };
        })
        .sort((a, b) => b.dateCreated - a.dateCreated);

      return userActivity;
    } catch (e) {
      throw e;
    }
  };
}
