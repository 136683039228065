enum FORM_STATES {
  idle = "idle",
  loading = "loading",
  success = "success",
  failure = "failure",
}

enum KycStatus {
  notVerified = "notVerified",
  pending = "pending",
  amlPending = "amlPending", // waiting for aml result
  amlPassed = "amlPassed", // final state
  amlFailed = "amlFailed",
  passed = "passed", // admin can set to override amlFailed
  rejected = "rejected",
}

export { FORM_STATES, KycStatus };
