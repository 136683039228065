import { call, put, all, takeLatest } from "redux-saga/effects";
import {
  getAuthSuccess,
  getAuthFailure,
  clearAuthSuccess,
} from "./Auth.actions";
import { initUser, clearUser } from "../User/User.actions";
import { clearSecurity } from "../Security/Security.actions";
import { clearBalance } from "../Balance/Balance.actions";
import { stopBalanceSync } from "../Tron/Tron.actions";
import {
  stopTransactionsSync,
  stopTransactionsSpeedSync,
  clearTransactions,
} from "../Transactions/Transactions.actions";
import { AuthService } from "./Auth.store";

function* getAuthSaga() {
  try {
    const authService = new AuthService();
    const { token, userId } = yield call(authService.getAuth);
    yield put(getAuthSuccess(token, userId));
    yield all([put(initUser({ token, userId }))]);
  } catch (error) {
    yield put(getAuthFailure(error));
  }
}

function* clearAuthSaga() {
  try {
    const authService = new AuthService();
    yield call(authService.clearAuth);
    yield put(clearAuthSuccess());
    yield all([
      put(clearUser()),
      put(clearSecurity()),
      put(clearBalance()),
      put(stopBalanceSync()),
      put(stopTransactionsSync()),
      put(stopTransactionsSpeedSync()),
      put(clearTransactions()),
    ]);
  } catch (error) {
    yield put(getAuthFailure(error));
  }
}

export function* authSagas() {
  yield takeLatest("GET_AUTH", getAuthSaga);
  yield takeLatest("CLEAR_AUTH", clearAuthSaga);
}
