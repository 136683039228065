import { BALANCE_STATES } from "./Balance.constants";

const initialState = {
  status: BALANCE_STATES.idle,
  swap: "0",
  rise: "0",
  bnb: "0",
  trc20Rise: "0",
  cash: "0",
  error: null,
};

const Balance = (
  state = initialState,
  action: {
    type: string;
    swap: any;
    rise: any;
    bnb: any;
    trc20Rise: any;
    cash: any;
    error: any;
  }
) => {
  switch (action.type) {
    case "INIT_BALANCE":
      return {
        ...state,
        status: BALANCE_STATES.loading,
        error: null,
      };
    case "GET_BALANCE_SUCCESS":
      return {
        ...state,
        status: BALANCE_STATES.success,
        rise: action.rise,
        swap: action.swap,
        bnb: action.bnb,
        error: null,
      };
    case "GET_TRC20_BALANCE_SUCCESS":
      return {
        ...state,
        trc20Rise: action.trc20Rise,
        cash: action.cash,
      };
    case "GET_BALANCE_FAILURE":
      return {
        ...state,
        status: BALANCE_STATES.failure,
        error: action.error,
      };
    case "CLEAR_BALANCE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default Balance;
