import { call, all, put, takeLatest } from "redux-saga/effects";
import { clearAuth } from "../Auth/Auth.actions";
import {
  getKycSuccess,
  getKycFailure,
  updateKycSuccess,
  updateKycFailure,
} from "./Kyc.actions";
import { KycService } from "./Kyc.store";

function* getKycSaga(action) {
  try {
    const { payload } = action;
    const kycService = new KycService();
    const data = yield call(kycService.getKyc, payload);
    yield put(getKycSuccess(data));
  } catch (error) {
    console.info(error);
    yield all([put(clearAuth()), put(getKycFailure(error))]);
  }
}

function* updateKycSaga(action) {
  try {
    const { payload } = action;
    const kycService = new KycService();
    const data = yield call(kycService.updateKyc, payload);
    yield put(updateKycSuccess(data));
  } catch (error) {
    const { step } = action;
    yield all([put(updateKycFailure(step, error.message))]);
  }
}

export function* kycSagas() {
  yield takeLatest("GET_KYC", getKycSaga);
  yield takeLatest("UPDATE_KYC", updateKycSaga);
}
