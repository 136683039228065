import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { Layout } from "antd";
import "./Page.scss";
const { Content } = Layout;

const RouteWrapper = ({ component: Component, ...rest }: RouteProps) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <>
          <Content className="Page">
            {Component && <Component {...routeProps} />}
          </Content>
        </>
      )}
    />
  );
};

export default RouteWrapper;
