import { SECURITY_STATES } from "./Security.constants";

const initialState = {
  status: SECURITY_STATES.idle,
  data: {},
  error: null,
};

const Security = (
  state = initialState,
  action: {
    type: string;
    data: any;
    error: any;
  }
) => {
  switch (action.type) {
    case "GET_SECURITY":
      return {
        ...state,
        status: SECURITY_STATES.loading,
        error: null,
      };
    case "GET_SECURITY_SUCCESS":
      return {
        ...state,
        status: SECURITY_STATES.success,
        data: action.data,
        error: null,
      };
    case "GET_SECURITY_FAILURE":
      return {
        ...initialState,
        status: SECURITY_STATES.failure,
        error: action.error,
      };
    case "CLEAR_SECURITY":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default Security;
