import { AuthService } from "./Auth.store";

export const getAuthSuccess = (token: string, userId: number) => ({
  type: "GET_AUTH_SUCCESS",
  token,
  userId,
});

export const getAuthFailure = (error: Error) => ({
  type: "GET_AUTH_FAILURE",
  error,
});

export const clearAuth = () => ({
  type: "CLEAR_AUTH",
});

export const clearAuthSuccess = () => ({
  type: "CLEAR_AUTH_SUCCESS",
});

export const doLogin = (formData: {
  email: string;
  password: string;
  code: string;
  remember: boolean;
  captcha: string;
}) => {
  const authService = new AuthService();
  const params = {
    ...formData,
    email: formData.email.trim(),
  };
  return authService.doLogin(params);
};

export const doResetTwoFa = (formData: { email: string; password: string }) => {
  const authService = new AuthService();
  return authService.doResetTwoFa(formData);
};

export const doPasswordReset = (formData: {
  email: string;
  captcha: string;
}) => {
  const authService = new AuthService();
  return authService.doPasswordReset(formData);
};

export const confirmResetTwoFa = (token: string) => {
  const authService = new AuthService();
  return authService.confirmResetTwoFa(token);
};

export const verifyEmail = (token: string) => {
  const authService = new AuthService();
  return authService.verifyEmail(token);
};

export const confirmSecurity = (token: string) => {
  const authService = new AuthService();
  return authService.confirmSecurity(token);
};

export const doSignup = (formData: {
  email: string;
  password: string;
  captcha: string;
}) => {
  const params = {
    ...formData,
    email: formData.email.trim(),
  };
  const authService = new AuthService();
  return authService.doSignup(params);
};

export const createPassword = (
  formData: {
    newPassword: string;
  },
  payload: { token: string }
) => {
  const authService = new AuthService();
  return authService.createPassword(formData, payload);
};

export const updatePassword = (
  formData: {
    currentPassword: string;
    newPassword: string;
  },
  payload: { token: string; userId: string }
) => {
  const authService = new AuthService();
  return authService.updatePassword(formData, payload);
};
