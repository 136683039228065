import { TRANSACTIONS_STATES } from "./Transactions.constants";
import _ from "lodash";

const initialState = {
  status: TRANSACTIONS_STATES.idle,
  data: [],
  page: null,
  pageSize: null,
  results: null,
  error: null,
};

const Transactions = (
  state = initialState,
  action: {
    type: string;
    data: any[];
    page: number;
    pageSize: number;
    results: number;
    error: any;
  }
) => {
  switch (action.type) {
    case "GET_TRANSACTIONS":
      return {
        ...state,
        status: TRANSACTIONS_STATES.loading,
        error: null,
      };
    case "GET_TRANSACTIONS_SUCCESS": {
      const data = _.unionBy(action.data, state.data, "date");

      return {
        ...state,
        status: TRANSACTIONS_STATES.success,
        data: data,
        page: action.page,
        pageSize: action.pageSize,
        results: action.results,
        error: null,
      };
    }
    case "GET_TRANSACTIONS_FAILURE":
      return {
        ...initialState,
        status: TRANSACTIONS_STATES.failure,
        error: action.error,
      };
    case "CLEAR_TRANSACTIONS":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default Transactions;
