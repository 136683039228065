import React, { Suspense, lazy } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { LoadingFrame, ScrollTop } from "../components/Shared";
import PrivateRouteWrapper from "./private/PrivateRouteWrapper";

const Signup = lazy(() => import("./public/Signup/Signup"));
const ResetPassword = lazy(() =>
  import("./public/ResetPassword/ResetPassword")
);
const ConfirmSecurity = lazy(() =>
  import("./public/ConfirmSecurity/ConfirmSecurity")
);
const ResetSecurity = lazy(() =>
  import("./public/ResetSecurity/ResetSecurity")
);
const VerifyEmail = lazy(() => import("./public/VerifyEmail/VerifyEmail"));

function Routes({ store }) {
  return (
    <Provider store={store}>
      <Router>
        <ScrollTop />
        <Suspense fallback={<LoadingFrame />}>
          <Switch>
            <Route path="/signup" exact children={<Signup />} />
            <Route
              path="/reset-pass/:token"
              exact
              children={<ResetPassword />}
            />
            <Route
              path="/reset-2fa/:token"
              exact
              children={<ResetSecurity />}
            />
            <Route
              path="/activate-2fa/:token"
              exact
              children={<ConfirmSecurity />}
            />
            <Route
              path="/auth/confirm/:token"
              exact
              children={<VerifyEmail />}
            />
            <PrivateRoute path="/">
              <PrivateRouteWrapper />
            </PrivateRoute>
          </Switch>
        </Suspense>
      </Router>
    </Provider>
  );
}

function PrivateRoute({ children, ...rest }) {
  return <Route {...rest} render={({ location }) => children} />;
}

export default Routes;
