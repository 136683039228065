enum AUTH_STATUS {
  IDLE = "idle",
  LOADING = "loading",
  SUCCESS = "success",
  FAILURE = "failure",
}

enum LOGIN_STATUS {
  IDLE = "IDLE",
  LOADING = "LOADING",
  TWOFA = "TWOFA",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  TWOFA_ERROR = "TWOFA_ERROR",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  VERIFY_EMAIL = "VERIFY_EMAIL",
}

export { AUTH_STATUS, LOGIN_STATUS };
