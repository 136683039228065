import axios from "axios";
import {
  BNB_DECIMALS,
  RISE_ADDRESS,
  RISE_ADDRESS_PROD,
  SWAP_ADDRESS,
  RISE_ABI,
  SWAP_ABI,
  BSC_RCP_ENDPOINTS,
  BSC_RCP_ENDPOINTS_PROD,
  BSC_API_ADDRESS,
  BSC_API_KEY,
} from "../../config";

import { divide, formatDecimalFromCentric } from "../../utils/formatNumber";
import { ethers } from "ethers";

class BscService {
  public provider: any;
  public web3: any;
  public riseContract: any;
  public swapContract: any;
  private hostIndex = 1;

  // REMOVE FOR PROD
  // public providerProd: any;
  // public riseContractProd: any;

  constructor() {
    const rcpEndpoints = BSC_RCP_ENDPOINTS.split(",");
    this.provider = new ethers.providers.JsonRpcProvider(rcpEndpoints[0]);
    // REMOVE FOR PROD
    /*
    const rcpEndpointsProd = BSC_RCP_ENDPOINTS_PROD.split(",");
    this.providerProd = new ethers.providers.JsonRpcProvider(
      rcpEndpointsProd[0]
    );
    */
  }

  private getNextFullHost() {
    const fullHosts = BSC_RCP_ENDPOINTS.split(",");
    const host = fullHosts[this.hostIndex];
    this.hostIndex =
      this.hostIndex === fullHosts.length - 1 ? 0 : ++this.hostIndex;
    return host;
  }

  protected async updateProvider() {
    this.provider = new ethers.providers.JsonRpcProvider(
      this.getNextFullHost()
    );
  }

  public getRiseContract = async () => {
    try {
      if (!this.provider) throw new Error("BSC service not initiated");

      return new ethers.Contract(RISE_ADDRESS, RISE_ABI, this.provider);
    } catch (e) {
      console.error(e);
      throw new Error(e.message);
    }
  };

  public getSwapContract = async () => {
    try {
      if (!this.provider) throw new Error("BSC service not initiated");

      return new ethers.Contract(SWAP_ADDRESS, SWAP_ABI, this.provider);
    } catch (e) {
      console.error(e);
      throw new Error(e.message);
    }
  };

  // REMOVE FOR PROD
  /*
  public getRiseContractProd = async () => {
    try {
      if (!this.providerProd) throw new Error("BSC service not initiated");

      return new ethers.Contract(
        RISE_ADDRESS_PROD,
        RISE_ABI,
        this.providerProd
      );
    } catch (e) {
      console.error(e);
      throw new Error(e.message);
    }
  };
  */

  public initContracts = async (counter = 0) => {
    try {
      if (!this.provider) throw new Error("BSC service not initiated");

      this.riseContract = await this.getRiseContract();
      this.swapContract = await this.getSwapContract();
      // REMOVE FOR PROD
      // this.riseContractProd = await this.getRiseContractProd();

      return;
    } catch (e) {
      if (counter < 3) {
        await this.updateProvider();
        return this.initContracts(++counter);
      }
      throw new Error("Error connecting to BSC service");
    }
  };

  public getPriceData = async () => {
    try {
      const blockNumberHex = await this.riseContract.getCurrentHour();
      // Remove for prod
      // const blockNumberHex = await this.riseContractProd.getCurrentHour();

      const blockNumber = Number(blockNumberHex);

      const swapPricePromise: any = axios.get(
        `https://wallet-api.centric.com/api/public/contract/cmc-cns-quote`
      );

      const prevPricePromise = this.riseContract.getBlockData(blockNumber - 1);
      const currentPricePromise = this.riseContract.getBlockData(blockNumber);
      const nextPricePromise = this.riseContract.getBlockData(blockNumber + 1);

      // Remove for prod
      /*const prevPricePromise = this.riseContractProd.getBlockData(
        blockNumber - 1
      );
      const currentPricePromise = this.riseContractProd.getBlockData(
        blockNumber
      );
      const nextPricePromise = this.riseContractProd.getBlockData(
        blockNumber + 1
      );*/

      const [
        { data: swapPriceResult },
        { _risePrice: prevRisePriceHex },
        { _risePrice: currentRisePriceHex },
        { _risePrice: nextRisePriceHex },
      ] = await Promise.all([
        swapPricePromise,
        prevPricePromise,
        currentPricePromise,
        nextPricePromise,
      ]);

      const swapPrice = swapPriceResult.data["5963"].quote.USD.price;

      const prevPrice = formatDecimalFromCentric(prevRisePriceHex);

      const currentPrice = formatDecimalFromCentric(currentRisePriceHex);

      const nextPrice = formatDecimalFromCentric(nextRisePriceHex);

      return {
        swapPrice: swapPrice,
        prev: {
          prevPrice,
          blockNumber: blockNumber - 1,
        },
        current: {
          currentPrice,
          blockNumber,
        },
        next: {
          nextPrice,
          blockNumber: blockNumber + 1,
        },
      };
    } catch (e) {
      console.info("Error getting price data");
      throw new Error("Error getting price data");
    }
  };

  public getBalanceData = async (address) => {
    try {
      if (!address) {
        return {
          rise: formatDecimalFromCentric(0),
          swap: formatDecimalFromCentric(0),
          bnb: "0",
        };
      }

      if (!this.riseContract || !this.swapContract) {
        await this.initContracts();
      }

      const bnbBalancePromise = this.provider.getBalance(address);
      const riseBalancePromise = this.riseContract.balanceOf(address);
      const swapBalancePromise = this.swapContract.balanceOf(address);

      const [bnbBalance, riseBalance, swapBalance] = await Promise.all([
        bnbBalancePromise,
        riseBalancePromise,
        swapBalancePromise,
      ]);

      const rise = formatDecimalFromCentric(riseBalance);
      const swap = formatDecimalFromCentric(swapBalance);
      const bnb = divide(Number(bnbBalance), BNB_DECIMALS);

      return {
        rise: rise,
        swap: swap,
        bnb: bnb,
      };
    } catch (e) {
      console.info(e);
      throw new Error("Error getting balance data");
    }
  };

  public getKeyMetrics = async () => {
    try {
      const [latestBurn, contractData] = await Promise.all([
        this.getBurnData(),
        axios.get(
          `https://wallet-api.centric.com/api/public/bsc/contract/info`
        ),
      ]);

      const circulatingRise = formatDecimalFromCentric(
        contractData?.data["rise"]?.circulatingSupply
      );

      return {
        latestBurn,
        circulatingRise,
      };
    } catch (e) {
      console.log(e);
      throw new Error("Error getting key metric data");
    }
  };

  public getBurnData = async () => {
    try {
      if (!this.riseContract) {
        return 0;
      }

      const currentBlock = await this.provider.getBlockNumber();
      // Remove for prod
      // const currentBlock = await this.providerProd.getBlockNumber();

      const fromBlock = currentBlock - 4800;

      const filter = this.riseContract.filters.DoBalance();

      const { data: response } = await axios.get(
        `${BSC_API_ADDRESS}/api?module=logs&action=getLogs&fromBlock=${fromBlock}&toBlock=${currentBlock}&topic0=${filter.topics[0]}&address=${RISE_ADDRESS}&apikey=${BSC_API_KEY}`
      );

      const events = response.result;
      events.sort((a, b) => Number(b.blockNumber) - Number(a.blockNumber));
      const abiInterface = new ethers.utils.Interface(RISE_ABI);

      const burns = events
        .map((e) => {
          const logDescription = abiInterface.parseLog({
            data: e.data,
            topics: e.topics,
          });

          return {
            ...e,
            ...logDescription,
          };
        })
        .map((e) => formatDecimalFromCentric(e.args.riseAmountBurnt) ?? 0);

      return burns[0];
    } catch (e) {
      console.log(e);
      throw new Error("Error getting burn data");
    }
  };
}

const bscService = new BscService();

export default bscService;
