export const getTronSuccess = () => ({
  type: "GET_TRON_SUCCESS",
});

export const getTronFailure = (error: Error) => ({
  type: "GET_TRON_FAILURE",
  error,
});

export const initBalanceSync = (wallet, tronWallet) => ({
  type: "INIT_BALANCE_SYNC",
  wallet,
  tronWallet,
});

export const startBalanceSync = () => ({
  type: "START_BALANCE_SYNC",
});

export const stopBalanceSync = () => ({
  type: "STOP_BALANCE_SYNC",
});

export const getBalance = (wallet) => ({
  type: "GET_BALANCE",
  wallet,
});
