import { all } from "redux-saga/effects";
import { authSagas } from "./Auth/Auth.sagas";
import { userSagas } from "./User/User.sagas";
import { tronSagas } from "./Tron/Tron.sagas";
import { kycSagas } from "./Kyc/Kyc.sagas";
import { securitySagas } from "./Security/Security.sagas";
import { transactionsSagas } from "./Transactions/Transactions.sagas";

function* rootSaga() {
  yield all([
    authSagas(),
    userSagas(),
    tronSagas(),
    kycSagas(),
    securitySagas(),
    transactionsSagas(),
  ]);
}

export default rootSaga;
