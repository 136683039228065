import { UserService } from "./User.store";

export const initUser = (payload: { token: string; userId: string }) => ({
  type: "INIT_USER",
  payload,
});

export const getUser = (payload: { token: string; userId: string }) => ({
  type: "GET_USER",
  payload,
});

export const checkUser = (payload: { token: string; userId: string }) => ({
  type: "CHECK_USER",
  payload,
});

export const getUserSuccess = (data: any) => ({
  type: "GET_USER_SUCCESS",
  data,
});

export const getUserFailure = (error: Error) => ({
  type: "GET_USER_FAILURE",
  error,
});

export const clearUser = () => ({
  type: "CLEAR_USER",
});

export const updateUser = (
  formData: {
    email: string;
    firstName: string;
    lastName: string;
    country: string;
  },
  payload: { token: string; userId: string }
) => {
  const userService = new UserService();
  return userService.updateUser(formData, payload);
};

export const getActivity = (payload: { token: string; userId: string }) => {
  const userService = new UserService();
  return userService.getActivity(payload);
};
