import { BalanceService } from "./Balance.store";

export const initBalance = () => ({
  type: "INIT_BALANCE",
});

export const getBalanceSuccess = (rise: any, swap: any, bnb: any) => ({
  type: "GET_BALANCE_SUCCESS",
  rise,
  swap,
  bnb,
});

export const getBalanceFailure = (error: Error) => ({
  type: "GET_BALANCE_FAILURE",
  error,
});

export const getTrc20BalanceSuccess = (trc20Rise: any, cash: any) => ({
  type: "GET_TRC20_BALANCE_SUCCESS",
  trc20Rise,
  cash,
});

export const clearBalance = () => ({
  type: "CLEAR_BALANCE",
});

export const getWalletInfo = (payload: { token: string; userId: string }) => {
  const balanceService = new BalanceService();
  return balanceService.getInfo(payload);
};
