import axios from "axios";
import { API_ADDRESS } from "../../config";
import {
  formatSunFromDecimal,
  numberFromLocaleString,
  minus,
  isLessOrEqual,
} from "../../utils/formatNumber";

export class TransactionActionService {
  public createTransaction = async (
    formData,
    payload,
    transactionType,
    feeInfo
  ) => {
    try {
      const { toAddress: to, amount: value, currency, code } = formData;
      const { cnsFee, cnrFee } = feeInfo;
      const { token, userId } = payload;

      const amount = numberFromLocaleString(value);

      let amountMinusFee: any = amount;
      if (transactionType === "convert") {
        if (currency === "rise") {
          amountMinusFee = minus(amount, cnsFee);
        }
        if (currency === "swap") {
          amountMinusFee = minus(amount, cnrFee);
        }
      } else {
        if (currency === "rise") {
          amountMinusFee = minus(amount, cnrFee);
        }
        if (currency === "swap") {
          amountMinusFee = minus(amount, cnsFee);
        }
      }

      if (isLessOrEqual(amountMinusFee, 0)) {
        return {
          validateStatus: "paymenterror",
          txId: null,
          errorFields: [],
        };
      }

      const computedAmount = formatSunFromDecimal(amountMinusFee);

      const type = transactionType === "convert" ? "convert-to" : "transfer";

      const { data, status } = await axios.post(
        `${API_ADDRESS}/users/${userId}/bsc/wallet-v2/${type}-${currency}`,
        {
          to,
          amount: computedAmount,
          code,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (status >= 400) {
        switch (status) {
          case 401: {
            switch (data.error.toLowerCase()) {
              case "unauthorized":
                return {
                  validateStatus: "error",
                  txid: null,
                  errorFields: [
                    {
                      name: ["code"],
                      errors: [data.message],
                    },
                  ],
                };
              case "payment required":
                return {
                  validateStatus: "paymenterror",
                  txId: null,
                  errorFields: [],
                };
              default:
                throw new Error(data.message);
            }
          }
          case 402: {
            switch (data.error.toLowerCase()) {
              case "payment required":
                return {
                  validateStatus: "paymenterror",
                  txId: null,
                  errorFields: [],
                };
              default:
                throw new Error(data.message);
            }
          }
          default:
            throw new Error(data.message);
        }
      }
      const { txid } = data;
      return {
        validateStatus: "success",
        errorFields: [],
        txid: txid,
      };
    } catch (e) {
      return {
        validateStatus: "failed",
        txId: null,
        errorFields: [],
        error: e.message,
      };
    }
  };
}
