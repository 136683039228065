import { METRICS_STATES } from "./Metrics.constants";

const initialState = {
  status: METRICS_STATES.idle,
  latestBurn: null,
  circulatingRise: null,
  error: null,
};

const Metrics = (
  state = initialState,
  action: {
    type: string;
    latestBurn: any;
    circulatingRise: any;
    error: any;
  }
) => {
  switch (action.type) {
    case "GET_METRICS":
      return {
        ...state,
        status: METRICS_STATES.loading,
        error: null,
      };
    case "GET_METRICS_SUCCESS":
      return {
        ...state,
        status: METRICS_STATES.success,
        latestBurn: action.latestBurn,
        circulatingRise: action.circulatingRise,
        error: null,
      };
    case "GET_METRICS_FAILURE":
      return {
        ...state,
        status: METRICS_STATES.failure,
        error: action.error,
      };
    default:
      return state;
  }
};

export default Metrics;
