import { call, put, takeLatest, all } from "redux-saga/effects";
import { getSecuritySuccess, getSecurityFailure } from "./Security.actions";
import { SecurityService } from "./Security.store";
import { clearAuth } from "../Auth/Auth.actions";

function* getSecuritySaga(action) {
  try {
    const { payload } = action;
    const securityService = new SecurityService();
    const data = yield call(securityService.getSecurity, payload);
    yield put(getSecuritySuccess(data));
  } catch (error) {
    yield all([put(clearAuth())]);
    yield put(getSecurityFailure(error));
  }
}

export function* securitySagas() {
  yield takeLatest("GET_SECURITY", getSecuritySaga);
}
