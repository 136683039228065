import axios from "axios";
import { API_ADDRESS } from "../../config";

export class SecurityService {
  public getSecurity = async (payload) => {
    try {
      const { token, userId } = payload;
      const { data } = await axios.get(
        `${API_ADDRESS}/users/${userId}/security`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (e) {
      throw e;
    }
  };

  public updateSecurity = async (payload) => {
    try {
      const { token, userId, code, isActive, password } = payload;
      const { status, data } = await axios.put(
        `${API_ADDRESS}/users/${userId}/security`,
        { isActive, code, password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );
      if (status >= 400) {
        switch (status) {
          case 400: {
            const errors: any = [];
            data.forEach((err) => {
              errors.push({
                name: err.property,
                error: [Object.values(err.constraints)],
              });
            });
            return {
              status: "error",
              errorFields: errors.map((err) => {
                return {
                  name: [err.name],
                  errors: [err.error],
                };
              }),
            };
          }
          case 401: {
            const { message, updated } = data;
            if (message.toLowerCase() === "incorrect password") {
              return {
                status: "error",
                errorFields: [
                  {
                    name: ["password"],
                    errors: [message],
                  },
                ],
              };
            } else if (
              message.toLowerCase() === "2fa code is incorrect or expired"
            ) {
              return {
                status: "error",
                errorFields: [
                  {
                    name: ["code"],
                    errors: [message],
                  },
                ],
              };
            } else if (updated) {
              return {
                status: "changed",
              };
            }
            break;
          }
          default:
            throw new Error();
        }
      }
      const { activationPending } = data;
      return {
        status: "success",
        errorFields: [],
        activationPending: activationPending,
      };
    } catch (e) {
      throw new Error("Error updating security");
    }
  };
}
