import { combineReducers } from "redux";
import Auth from "./Auth/Auth.reducer";
import User from "./User/User.reducer";
import Tron from "./Tron/Tron.reducer";
import Balance from "./Balance/Balance.reducer";
import Price from "./Price/Price.reducer";
import Kyc from "./Kyc/Kyc.reducer";
import Security from "./Security/Security.reducer";
import Transactions from "./Transactions/Transactions.reducer";
import Metrics from "./Metrics/Metrics.reducer";

const combinedReducers = combineReducers({
  Auth,
  User,
  Tron,
  Balance,
  Price,
  Kyc,
  Security,
  Transactions,
  Metrics,
});

const rootReducer = (state: any, action: any) => {
  return combinedReducers(state, action);
};

export default rootReducer;
