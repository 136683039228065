import { call, all, put, takeLatest } from "redux-saga/effects";
import { getUserSuccess, getUserFailure } from "./User.actions";
import { initBalance, clearBalance } from "../Balance/Balance.actions";
import { initBalanceSync, startBalanceSync } from "../Tron/Tron.actions";
import { clearAuth } from "../Auth/Auth.actions";
import { UserService } from "./User.store";

function* getUserSaga(action) {
  try {
    const { payload } = action;
    const userService = new UserService();
    const data = yield call(userService.getUser, payload);
    yield all([put(getUserSuccess(data)), put(initBalance())]);
    const { bscWallet: wallet, tronWallet } = data;
    yield put(initBalanceSync(wallet, tronWallet));
    yield put(startBalanceSync());
  } catch (error) {
    yield all([
      put(clearAuth()),
      put(getUserFailure(error)),
      put(clearBalance()),
    ]);
  }
}

export function* userSagas() {
  yield takeLatest("INIT_USER", getUserSaga);
  yield takeLatest("GET_USER", getUserSaga);
  yield takeLatest("CHECK_USER", getUserSaga);
}
