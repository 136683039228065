import axios from "axios";
import { API_ADDRESS } from "../../config";

export class BalanceService {
  public getInfo = async (payload) => {
    try {
      const { token, userId } = payload;
      const { data } = await axios.get(
        `${API_ADDRESS}/users/${userId}/bsc/wallet-v2/info`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (e) {
      throw e;
    }
  };
}
