export const getMetrics = () => ({
  type: "GET_METRICS",
});

export const getMetricsSuccess = (latestBurn: any, circulatingRise: any) => ({
  type: "GET_METRICS_SUCCESS",
  latestBurn,
  circulatingRise,
});

export const getMetricsFailure = (error: Error) => ({
  type: "GET_METRICS_FAILURE",
  error,
});
