import BigNumber from "bignumber.js";

BigNumber.config({ DECIMAL_PLACES: 8 });
BigNumber.set({ ROUNDING_MODE: BigNumber.ROUND_FLOOR });

const BigSun = BigNumber.clone({ DECIMAL_PLACES: 0 });
BigSun.set({ ROUNDING_MODE: BigNumber.ROUND_FLOOR });

const divide = (n, d) => new BigNumber(n).dividedBy(d).toString();
const multiply = (n, d) => new BigNumber(n).times(d).toString();
const minus = (n, d) => new BigNumber(n).minus(d).toString();
const add = (n, d) => new BigNumber(n).plus(d).toString();
const isLessOrEqual = (n, d) => new BigNumber(n).isLessThanOrEqualTo(d);
const isGreater = (n, d) => new BigNumber(n).isGreaterThan(d);
const isGreaterOrEqual = (n, d) => new BigNumber(n).isGreaterThanOrEqualTo(d);
const isBnNan = (n) => new BigNumber(n).isNaN();

const formatDecimalFromCentric = (value) => {
  const result = new BigNumber(value.toString()).dividedBy(100000000);
  return result.toString();
};

const formatSunFromDecimal = (value) => {
  const result = new BigSun(value.toString()).times(100000000);
  return result.toString();
};

const formatLocal = (number, minDecimals, maxDecimals) =>
  new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
  }).format(number);

const formatCurrency = (value) => {
  const trunc = new BigNumber(value).toFixed();
  const string = trunc.toString();
  const parts = string.split(".");
  return (
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (parts[1] || parts[1] === "" ? "." + parts[1] : "")
  );
};

function numberFromLocaleString(stringValue) {
  var parts = Number(1111.11)
    .toLocaleString("en-GB")
    .replace(/\d+/g, "")
    .split("");
  if (stringValue === null) return "0";
  if (parts.length == 1) {
    parts.unshift("");
  }
  return String(stringValue)
    .replace(new RegExp(parts[0].replace(/\s/g, " "), "g"), "")
    .replace(parts[1], ".")
    .toString();
}

const calculateDecimals = (number, min, max) => {
  function decimalCount(checknumber) {
    const numberAsString = checknumber.toString();
    if (numberAsString.includes(".")) {
      return numberAsString.split(".")[1].length;
    }
    return 0;
  }
  function integerCount(checknumber) {
    const numberAsString = checknumber.toString();
    if (numberAsString.includes(".")) {
      return numberAsString.split(".")[0].length;
    }
    return numberAsString.length;
  }

  const integerLength = integerCount(number);

  let decimals = min;
  if (decimalCount(number) > 0) {
    decimals = max;
  }

  if (integerLength > 1) decimals = max;

  if (integerLength > 3) decimals = min;

  return decimals;
};

export {
  divide,
  multiply,
  minus,
  add,
  isLessOrEqual,
  isGreaterOrEqual,
  isGreater,
  isBnNan,
  calculateDecimals,
  formatDecimalFromCentric,
  formatLocal,
  formatSunFromDecimal,
  formatCurrency,
  numberFromLocaleString,
};
