import { FORM_STATES, KycStatus } from "./Kyc.constants";

const initialState = {
  formStatus: FORM_STATES.idle,
  formStep: 0,
  kycStatus: "loading",
  data: {},
  documentErrors: [],
  selfieErrors: [],
  message: null,
  error: null,
};

const Kyc = (
  state = initialState,
  action: {
    type: string;
    data: any;
    step: number;
    message: string;
    error: any;
  }
) => {
  switch (action.type) {
    case "GET_KYC":
      return {
        ...state,
        formStatus: FORM_STATES.loading,
        kycStatus: "loading",
        error: null,
      };
    case "GET_KYC_SUCCESS":
      return {
        ...state,
        formStatus: FORM_STATES.success,
        kycStatus: action.data.status,
        documentErrors:
          action.data.errors && action.data.errors.document
            ? action.data.errors.document
            : [],
        selfieErrors:
          action.data.errors && action.data.errors.selfie
            ? action.data.errors.selfie
            : [],
        data: action.data,
        error: null,
      };
    case "GET_KYC_FAILURE":
      return {
        ...initialState,
        formStatus: FORM_STATES.failure,
        error: action.error,
      };
    case "UPDATE_KYC":
      return {
        ...state,
        formStatus: FORM_STATES.loading,
        message: null,
        error: null,
      };
    case "UPDATE_KYC_SUCCESS":
      return {
        ...state,
        formStatus: FORM_STATES.success,
        data: action.data,
        kycStatus: action.data.status,
        documentErrors: state.formStep === 1 ? [] : state.documentErrors,
        selfieErrors: state.formStep === 2 ? [] : state.selfieErrors,
        formStep:
          action.data.status === KycStatus.pending ? 0 : state.formStep + 1,
        message: null,
        error: null,
      };
    case "UPDATE_KYC_FAILURE":
      return {
        ...state,
        formStatus: FORM_STATES.success,
        formStep: action.step,
        message: action.message,
      };
    case "NAV_KYC":
      return {
        ...state,
        formStep: action.step,
        message: null,
      };
    default:
      return state;
  }
};

export default Kyc;
