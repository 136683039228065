const TOKEN = {
  rise: {
    nameLong: "Centric Rise",
    nameShort: "Rise",
    ticker: "CNR",
    color: "rgba(14,136,255,1)",
    weakColor: "rgba(145,201,255,1)",
  },
  swap: {
    nameLong: "Centric Swap",
    nameShort: "Swap",
    ticker: "CNS",
    price: 0,
    color: "rgba(39,188,52,1)",
    weakColor: "rgba(172,228,174,1)",
  },
};

export { TOKEN };
