export const getKyc = (payload: { token: string; userId: string }) => ({
  type: "GET_KYC",
  payload,
});

export const getKycSuccess = (data: any) => ({
  type: "GET_KYC_SUCCESS",
  data,
});

export const getKycFailure = (error: Error) => ({
  type: "GET_KYC_FAILURE",
  error,
});

export const navKyc = (step) => ({
  type: "NAV_KYC",
  step,
});

export const updateKyc = (
  payload: {
    token: string;
    userId: string;
    kycData;
  },
  step: number
) => ({
  type: "UPDATE_KYC",
  payload,
  step,
});

export const updateKycSuccess = (data: any) => ({
  type: "UPDATE_KYC_SUCCESS",
  data,
});

export const updateKycFailure = (step, message) => ({
  type: "UPDATE_KYC_FAILURE",
  step,
  message,
});
