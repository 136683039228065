import axios from "axios";
import { chain } from "lodash";
import { format } from "date-fns";
import {
  API_ADDRESS,
  RISE_CONTRACT_ADDRESS,
  RISE_ADDRESS,
  BSC_MINTSAVER_ADDRESS,
  TRON_MINTSAVER_ADDRESS,
  FEE_ADDRESS,
} from "../../config";
import { TOKEN } from "../../constants/tokens";

export class TransactionsService {
  public getTransactions = async (payload) => {
    try {
      const { token, userId, tronWallet, wallet, context } = payload;

      const query = context === "all" ? "" : `?token=${TOKEN[context].ticker}`;

      let data: any = [];
      let page,
        pageSize = 0;

      if (wallet) {
        const { data: bscResult } = await axios.get(
          `${API_ADDRESS}/public/bsc/address/${wallet}/transactions-v2${query}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const {
          data: bscData,
          page: bscPage,
          pageSize: bscPageSize,
        } = bscResult;

        const returnData = bscData
          .filter(
            (item) => item.to?.toLowerCase() !== FEE_ADDRESS.toLowerCase()
          )
          .map((item) => ({
            ...item,
            chain: "bsc",
          }));

        data = returnData;
        page = bscPage;
        pageSize = bscPageSize;
      }

      if (tronWallet) {
        const { data: tronResult } = await axios.get(
          `${API_ADDRESS}/users/${userId}/transactions${query}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { data: tronData, pageSize: tronPageSize } = tronResult;

        const returnData = tronData.map((item) => ({
          ...item,
          chain: "tron",
        }));

        pageSize += tronPageSize;
        returnData.forEach((item) => data.push(item));
      }

      if (!data.length) {
        return {
          data: [],
          results: 0,
          page: 0,
          pageSize: 0,
        };
      }

      const namedTransactions = data
        .filter(
          (txn) =>
            txn.to?.toLowerCase() !== RISE_CONTRACT_ADDRESS?.toLowerCase() &&
            txn.to !== RISE_ADDRESS?.toLowerCase()
        )
        .map((txn) => {
          if (txn.from.toLowerCase() === BSC_MINTSAVER_ADDRESS?.toLowerCase()) {
            return {
              ...txn,
              type: "receive",
              name: "Received BSC Airdrop",
            };
          }
          if (
            txn.from?.toLowerCase() === TRON_MINTSAVER_ADDRESS?.toLowerCase()
          ) {
            return {
              ...txn,
              type: "receive",
              name: "Received TRON Airdrop",
            };
          }
          switch (txn.action) {
            case "ConvertToRise":
              return {
                ...txn,
                type: "convert",
                name: "Converted To Rise",
                token: "CNS",
              };
            case "ConvertToCash":
              return {
                ...txn,
                type: "convert",
                name: "Converted To Cash",
                token: "CNR",
              };
            case "ConvertToSwap":
              return {
                ...txn,
                type: "convert",
                name: "Converted To Swap",
                token: "CNR",
              };
            default: {
              switch (txn.type) {
                case "send":
                  return {
                    ...txn,
                    name: "Sent",
                  };
                case "receive":
                  return {
                    ...txn,
                    name: "Received",
                  };
                default:
                  return {
                    ...txn,
                    name: "Sent",
                  };
              }
            }
          }
        });

      const transactionGrouped = chain(namedTransactions)
        .groupBy((txn) => txn.txid)
        .map((txns) => {
          const items = removeTxns(txns);
          return items;
        })
        .flatten()
        .value();

      const dayGrouped = chain(transactionGrouped)
        .groupBy((txn) => format(txn.date, "LLL d, yyyy"))
        .map((txns, key) => ({ date: key, transactions: txns }))
        .value();

      return {
        data: dayGrouped,
        results: dayGrouped.length,
        page: page,
        pageSize: pageSize,
      };
    } catch (e) {
      console.info(e);
      throw e;
    }
  };

  public getTransactionsWithFailed = async (payload) => {
    try {
      const { token, wallet, context } = payload;
      const query = context === "all" ? "" : `?token=${TOKEN[context].ticker}`;

      const passedResult: any = axios.get(
        `${API_ADDRESS}/public/bsc/address/${wallet}/transactions-v2${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const failedResult: any = axios.get(
        `${API_ADDRESS}/public/bsc/address/${wallet}/failed_transactions${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const [
        { data: passedResults },
        { data: failedResults },
      ] = await Promise.all([passedResult, failedResult]);

      const { data: passedData, page, pageSize } = passedResults;
      const { data: failedData } = failedResults;
      const data = passedData.concat(failedData);

      const namedTransactions = data
        .filter((item) => item.to?.toLowerCase() !== FEE_ADDRESS.toLowerCase())
        .filter(
          (txn) =>
            txn.to?.toLowerCase() !== RISE_CONTRACT_ADDRESS?.toLowerCase() &&
            txn.to !== RISE_ADDRESS?.toLowerCase()
        )
        .map((txn) => {
          if (txn.from.toLowerCase() === BSC_MINTSAVER_ADDRESS?.toLowerCase()) {
            return {
              ...txn,
              type: "receive",
              name: "Received BSC Airdrop",
            };
          }
          if (
            txn.from?.toLowerCase() === TRON_MINTSAVER_ADDRESS?.toLowerCase()
          ) {
            return {
              ...txn,
              type: "receive",
              name: "Received TRON Airdrop",
            };
          }
          switch (txn.action) {
            case "ConvertToRise":
              return {
                ...txn,
                type: "convert",
                name: "Converted To Rise",
                token: "CNS",
              };
            case "ConvertToCash":
              return {
                ...txn,
                type: "convert",
                name: "Converted To Cash",
                token: "CNR",
              };
            case "ConvertToSwap":
              return {
                ...txn,
                type: "convert",
                name: "Converted To Swap",
                token: "CNR",
              };
            default: {
              switch (txn.type) {
                case "send":
                  return {
                    ...txn,
                    name: "Sent",
                  };
                case "receive":
                  return {
                    ...txn,
                    name: "Received",
                  };
                default:
                  return {
                    ...txn,
                    name: "Sent",
                  };
              }
            }
          }
        });

      const transactionGrouped = chain(namedTransactions)
        .groupBy((txn) => txn.txid)
        .map((txns) => {
          const items = removeTxns(txns);
          return items;
        })
        .flatten()
        .value();

      const dayGrouped = chain(transactionGrouped)
        .groupBy((txn) => format(txn.date, "LLL d, yyyy"))
        .map((txns, key) => ({ date: key, transactions: txns }))
        .value();

      return {
        data: dayGrouped,
        results: dayGrouped.length,
        page: page,
        pageSize: pageSize,
      };
    } catch (e) {
      console.info(e);
      throw e;
    }
  };
}

const removeTxns = (txns) => {
  if (txns.length === 3) {
    const filtered = txns.filter((txn) => txn.type !== "send");
    if (filtered[0].type === "convert") return filtered.reverse();
    return filtered;
  }
  if (txns.length === 2) {
    const filtered = txns.filter((txn) => txn.type !== "send");
    if (filtered[0].type === "convert") return filtered.reverse();
    return filtered;
  }
  return txns;
};
